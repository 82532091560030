import React, { useEffect, useState } from "react";
import {
  convertDayOfWeekToString,
  convertMonthToString,
  tagIcon,
} from "../utils";
import Loading from "./Loading";
import { Link } from "react-router-dom";

function DevLogs() {
  const [loading, setLoading] = useState(true);
  const [devlogs, setDevlogs] = useState([]);

  useEffect(() => {
    fetch("https://api.nathanmlee.com/devlogs")
      .then(async (res) => {
        if (!res.ok) return [];
        return await res.json();
      })
      .then((json) => {
        setDevlogs(json);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (!loading) {
    return (
      <div className="text-center">
        <h1>DevLogs</h1>
        {devlogs && devlogs.length > 0 ? (
          <div className="DevLogs">
            {devlogs.map((devlog, index) => {
              const date = new Date(devlog.date);
              return (
                <div key={devlog._id || index} className="DevLog">
                  {devlog.thumbnail && devlog.thumbnail.src ? (
                    <div className="thumbnail">
                      <Link to={"/devlogs/" + devlog.slug}>
                        <img
                          src={
                            "https://api.nathanmlee.com/images/" +
                            devlog.thumbnail.src
                          }
                          alt={devlog.thumbnail.alt}
                        />
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="preview">
                    <h3 className="title">
                      <Link to={"/devlogs/" + devlog.slug}>{devlog.title}</Link>
                    </h3>
                    <div className="meta">
                      <span className="author">By {devlog.author}</span>
                      <span className="date">
                        <small>
                          {convertDayOfWeekToString(date.getDay() + 1, {
                            abbreviated: true,
                          })}{" "}
                          {convertMonthToString(date.getMonth() + 1, {
                            abbreviated: true,
                          })}{" "}
                          {date.getDate()} {date.getFullYear()}
                        </small>
                      </span>
                    </div>
                  </div>
                  <div className="tags">
                    {devlog.tags.map((tag, index) => {
                      return (
                        <span key={index} className="tag">
                          <Link className={tag} to="#">
                            {tagIcon(tag)} {tag}
                          </Link>
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p>
            <em>Sorry, there are no devlogs yet.</em>
          </p>
        )}
      </div>
    );
  } else if (loading) {
    return <Loading />;
  }
}

export default DevLogs;
