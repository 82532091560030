import { RiUser3Fill, RiUser3Line } from "@remixicon/react";
import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav className="App-nav">
      <Link to="/about">About Me</Link>
      <Link to="/devlogs">DevLogs</Link>
      <Link to="/projects">Projects</Link>
      <Link to="/support">Support</Link>
      <Link to="/account" className="circle avatar">
        <RiUser3Line></RiUser3Line>
      </Link>
    </nav>
  );
}

export default Nav;
