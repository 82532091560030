import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import Loading from "./Loading";
import {
  convertDayOfWeekToString,
  convertMonthToString,
  createSafeMarkup,
  tagIcon,
} from "../utils";

function DevLog() {
  const [loading, setLoading] = useState(true);
  const [devlog, setDevlog] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    fetch(`https://api.nathanmlee.com/devlogs/${slug}`)
      .then(async (res) => {
        if (!res.ok) return {};
        return await res.json();
      })
      .then((json) => {
        setDevlog(json);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [slug]);

  if (!loading && devlog && devlog._id) {
    const date = new Date(devlog.date);
    return (
      <div className="DevLog">
        {devlog.thumbnail && devlog.thumbnail.src ? (
          <div className="thumbnail">
            <img
              src={"https://api.nathanmlee.com/images/" + devlog.thumbnail.src}
              alt={devlog.thumbnail.alt}
            />
          </div>
        ) : (
          ""
        )}
        <h1 dangerouslySetInnerHTML={createSafeMarkup(devlog.title)}></h1>
        <div dangerouslySetInnerHTML={createSafeMarkup(devlog.body)}></div>
        <div className="meta">
          <span className="author">
            <small>By {devlog.author}</small>
          </span>
          <span>
            <small>on</small>
          </span>
          <span className="date">
            <small>
              {convertDayOfWeekToString(date.getDay() + 1, {
                abbreviated: true,
              })}{" "}
              {convertMonthToString(date.getMonth() + 1, {
                abbreviated: true,
              })}{" "}
              {date.getDate()} {date.getFullYear()}
            </small>
          </span>
        </div>
        <div className="tags">
          {devlog.tags.map((tag, index) => {
            return (
              <span key={index} className="tag">
                <Link className={tag} to="#">
                  {tagIcon(tag)} {tag}
                </Link>
              </span>
            );
          })}
        </div>
      </div>
    );
  } else if (loading) {
    return <Loading />;
  } else {
    return <NotFound />;
  }
}

export default DevLog;
