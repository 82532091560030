import React from "react";

function Home() {
  return (
    <div className="text-center">
      <h1>nathanmlee</h1>
      <p>
        <em>This page is coming soon.</em>
      </p>
    </div>
  );
}

export default Home;
