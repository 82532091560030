import React from "react";

function Support() {
  return (
    <div className="text-center">
      <h1>Support</h1>
      <p>
        <em>This page is coming soon.</em>
      </p>
    </div>
  );
}

export default Support;
