import React from "react";

function NotFound() {
  return (
    <div className="text-center">
      <h1>Not Found</h1>
      <p>
        <em>Sorry, the content you are looking for could not be found.</em>
      </p>
    </div>
  );
}

export default NotFound;
