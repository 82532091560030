import React from "react";

function Register() {
  return (
    <div className="text-center">
      <h1>Register</h1>
      <p>
        <em>Sorry, this feature is currently disabled.</em>
      </p>
    </div>
  );
}

export default Register;
