import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";

function Header() {
  return (
    <header className="App-header">
      <Link to="/" className="App-logo">
        nathanmlee
      </Link>
      <Nav />
    </header>
  );
}

export default Header;
