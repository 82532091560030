import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import About from "./pages/About";
import DevLog from "./pages/DevLog";
import DevLogs from "./pages/DevLogs";
import Projects from "./pages/Projects";
import Support from "./pages/Support";
import Account from "./pages/Account";
import Register from "./pages/Register";
import Login from "./pages/Login";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="devlogs/:slug" element={<DevLog />} />
          <Route path="devlogs" element={<DevLogs />} />
          <Route path="projects" element={<Projects />} />
          <Route path="support" element={<Support />} />
          <Route path="account" element={<Account />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
