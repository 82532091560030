import DOMPurify from "dompurify";
import {
  RiReactjsLine,
  RiNodejsLine,
  RiPhpLine,
  RiHtml5Line,
  RiCss3Line,
  RiJavaLine,
  RiJavascriptLine,
  RiFileLine,
  RiDatabase2Line,
} from "@remixicon/react";

export const today = new Date();

export function createSafeMarkup(dirty) {
  return { __html: DOMPurify.sanitize(dirty) };
}

export function tagIcon(tag) {
  switch (tag) {
    case "react":
      return <RiReactjsLine></RiReactjsLine>;
    case "nodejs":
      return <RiNodejsLine></RiNodejsLine>;
    case "php":
      return <RiPhpLine></RiPhpLine>;
    case "html":
      return <RiHtml5Line></RiHtml5Line>;
    case "html5":
      return <RiHtml5Line></RiHtml5Line>;
    case "css":
      return <RiCss3Line></RiCss3Line>;
    case "css3":
      return <RiCss3Line></RiCss3Line>;
    case "javascript":
      return <RiJavascriptLine></RiJavascriptLine>;
    case "database":
      return <RiDatabase2Line></RiDatabase2Line>;
    case "mysql":
      return <RiDatabase2Line></RiDatabase2Line>;
    case "nedb":
      return <RiDatabase2Line></RiDatabase2Line>;
    case "java":
      return <RiJavaLine></RiJavaLine>;
    default:
      return <RiFileLine></RiFileLine>;
  }
}

export function convertDayOfWeekToString(
  dayOfWeek,
  opts = { abbreviated: false }
) {
  let text;

  switch (dayOfWeek) {
    case 1:
      text = "Sunday";
      break;
    case 2:
      text = "Monday";
      break;
    case 3:
      text = "Tuesday";
      break;
    case 4:
      text = "Wednesday";
      break;
    case 5:
      text = "Thursday";
      break;
    case 6:
      text = "Friday";
      break;
    case 7:
      text = "Saturday";
      break;
    default:
      text = "";
      break;
  }

  if (opts.abbreviated) {
    text = text.slice(0, 3);
  }

  return text;
}

export function convertMonthToString(month, opts = { abbreviated: false }) {
  let text;

  switch (month) {
    case 1:
      text = "January";
      break;
    case 2:
      text = "February";
      break;
    case 3:
      text = "March";
      break;
    case 4:
      text = "April";
      break;
    case 5:
      text = "May";
      break;
    case 6:
      text = "June";
      break;
    case 7:
      text = "July";
      break;
    case 8:
      text = "August";
      break;
    case 9:
      text = "September";
      break;
    case 10:
      text = "October";
      break;
    case 11:
      text = "November";
      break;
    case 12:
      text = "December";
      break;
    default:
      text = "";
      break;
  }

  if (opts.abbreviated) {
    text = text.slice(0, 3);
  }

  return text;
}
