import React from "react";
import { today } from "../utils";

function Footer() {
  return (
    <footer className="App-footer">
      <p>
        <small>
          &copy; {today.getFullYear()} nathanmlee. All rights reserved.
        </small>
      </p>
    </footer>
  );
}

export default Footer;
