import React from "react";

function Projects() {
  return (
    <div className="text-center">
      <h1>Projects</h1>
      <p>
        <em>This page is coming soon.</em>
      </p>
    </div>
  );
}

export default Projects;
