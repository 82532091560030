import React from "react";

function Login() {
  return (
    <div className="text-center">
      <h1>Login</h1>
      <p>
        <em>Sorry, this feature is currently disabled.</em>
      </p>
    </div>
  );
}

export default Login;
