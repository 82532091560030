import React from "react";

function Loading() {
  return (
    <div className="text-center">
      <h1>Loading</h1>
      <p>
        <em>This shouldn't take long, please wait...</em>
      </p>
    </div>
  );
}

export default Loading;
