import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Account() {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    setAccount(null);
  }, []);

  return (
    <div className="text-center">
      {account?._id ? (
        <>
          <h1>Account</h1>
          <p>
            <em>This page is coming soon.</em>
          </p>
        </>
      ) : (
        <div>
          <h1>Unauthorized</h1>
          <p>
            In order to access this page, you must either{" "}
            <Link to="/register">register</Link> or{" "}
            <Link to="/login">login</Link>.
          </p>
        </div>
      )}
    </div>
  );
}

export default Account;
