import React from "react";

function About() {
  return (
    <div className="text-center">
      <h1>About Me</h1>
      <p>
        <em>This page is coming soon.</em>
      </p>
    </div>
  );
}

export default About;
